import React from "react";
import PropTypes from "prop-types";
import withLocation from "../../../components/ui/WithLocation.js";
import { Row, Col, Typography } from "antd";
import { StaticImage } from "gatsby-plugin-image";
const Text = Typography;

const NivLogoWithLocation = ({ search, slug }) => {
  const { embedded } = search;
  if (embedded !== "true") {
    return (
      <Row justify="center">
        <Col style={{ width: "200px", padding: "48px 0 48px 0" }}>
          <StaticImage
            src="../../../images/client/niv_acuteboekje/logo.png"
            alt="NIV logo"
          />
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

NivLogoWithLocation.propTypes = {
  search: PropTypes.object,
};

export default withLocation(NivLogoWithLocation);
