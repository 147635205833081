import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { PageHeader, ConfigProvider } from "antd";
import InfoDrawer from "../ui/InfoDrawer";

import NivLogoWithLocation from "../ui/NivLogoWithLocation";
import NivChapterNavigationWithLocation from "../ui/NivChapterNavigationWithLocation";
import "../styles/layout.scss";

export default function PageTemplate({ data: { mdx } }) {
  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: "#882941",
        fontSizeBase: "18px",
      },
    });
  });
  return (
    <ConfigProvider>
      <div className="styles-niv_acuteboekje">
        <PageHeader
          style={{
            borderBottom: "1px solid #f0f0f0",
            width: "100%",
            backgroundColor: "#fff",
            padding: "5px 20px",
          }}
          title={mdx.frontmatter.title}
          extra={[
            <NivChapterNavigationWithLocation
              chapter={mdx.frontmatter.chapter}
              chapterUrl={mdx.frontmatter.chapter_url}
            />,
            <InfoDrawer
              client={mdx.frontmatter.client_display}
              source={mdx.frontmatter.source}
              added={mdx.frontmatter.added}
              edit={mdx.frontmatter.edit}
              chapter={mdx.frontmatter.chapter}
            />,
          ]}
        />
        <MDXRenderer>{mdx.body}</MDXRenderer>
        <NivLogoWithLocation />
        <div data-iframe-height></div>
        <script
          type="text/javascript"
          src="/js/iframeresizer/4.2.9/iframeResizer.contentWindow.min.js"
        ></script>
      </div>
    </ConfigProvider>
  );
}

export const pageQuery = graphql`
  query NivAcuteBoekjeQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        client_display
        source
        edit
        chapter
        chapter_url
      }
    }
  }
`;
