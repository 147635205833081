import React from "react";
import { Drawer, Button, Statistic, Typography } from "antd";
import { QuestionOutlined, ShareAltOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

class InfoDrawer extends React.Component {
  constructor() {
    super();
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Button
          onClick={this.showDrawer}
          icon={<QuestionOutlined />}
          className="niv-button"
        >
          <span className="hide-for-small">Over..</span>
        </Button>
        <Drawer
          title="Over dit algoritme"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          style={{ zIndex: "150000" }}
          width="220"
          footer={
            <div
              style={{
                padding: "20px",
                backgroundColor: "#228be6",
                borderRadius: "10px",
                boxShadow: "4px 4px 10px #dcdcdc",
              }}
            >
              <ShareAltOutlined
                style={{
                  transform: "rotate(90deg)",
                  color: "white",
                  fontSize: "24px",
                  margin: "0 0 10px 0",
                }}
              />
              <Paragraph
                style={{ fontSize: "80%", margin: "0", color: "white" }}
              >
                <a
                  target="_blank"
                  href="https://www.inflowchart.com/?utm_source=hetacuteboekje.nl"
                  rel="noreferrer noopener"
                  style={{ color: "white" }}
                >
                  Dit algoritme is door <i>inFlow interactive charts</i>{" "}
                  gemaakt. Klik voor meer informatie.
                </a>
              </Paragraph>
            </div>
          }
        >
          {this.props.client && (
            <Statistic
              title="Door:"
              value="Nederlandse Internisten Vereniging (NIV)"
              valueStyle={{ fontSize: "14px", marginBottom: "30px" }}
            />
          )}
          <Statistic
            title="Bron:"
            value={this.props.source}
            valueStyle={{ fontSize: "14px", marginBottom: "30px" }}
          />
          {this.props.chapter && (
            <Statistic
              title="Hoofdstuk:"
              value={this.props.chapter}
              valueStyle={{ fontSize: "14px", marginBottom: "30px" }}
            />
          )}
          <Statistic
            title="Laatste wijziging:"
            value={this.props.edit}
            valueStyle={{ fontSize: "14px", marginBottom: "30px" }}
          />
          {this.props.shorturl && (
            <>
              <Text
                type="secondary"
                style={{ fontSize: "12px", marginBottom: "6px" }}
              >
                Short URL:
              </Text>
              <Paragraph copyable={{ text: this.props.shorturl }}>
                {this.props.shorturl}
              </Paragraph>
            </>
          )}
        </Drawer>
      </>
    );
  }
}

export default InfoDrawer;
