import React from "react";
import PropTypes from "prop-types";
import withLocation from "../../../components/ui/WithLocation.js";
import { Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";

const NivChapterNavigationWithLocation = ({ search, chapter, chapterUrl }) => {
  const { embedded } = search;
  if (embedded !== "true" && chapter) {
    return (
      <Tooltip title="Opent hetacuteboekje.nl">
        <a
          href={chapterUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="ant-btn secondary niv-button"
        >
          <LinkOutlined style={{ marginRight: "5px" }} />{" "}
          <span className="hide-for-small">Naar '{chapter}'</span>
        </a>
      </Tooltip>
    );
  } else {
    return null;
  }
};

NivChapterNavigationWithLocation.propTypes = {
  search: PropTypes.object,
};

export default withLocation(NivChapterNavigationWithLocation);
